<script>
	const { DateTime } = require("luxon");

	export default {
		name: "Landing",
		components: {},
		props: {},
		data() {
			return {
				countInitialized: false,
				rankData: [],
			};
		},
		created() {},
		mounted() {
			this.fetchTop();

			/*if (!this.countInitialized) {
				for (let x = 0; x < window.intervals.length; x++) {
					let intervalId = window.intervals[x];
					if (intervalId != undefined) {
						window.clearInterval(intervalId);
						console.log("Cleared interval ID ", intervalId);
					}
				}
				$(".countdown").countdown({
					image: "imgdb/digits.png",
					format: "dd:hh:mm:ss",
					digitWidth: 67,
					digitHeight: 90,
					endTime: DateTime.fromISO("2023-01-28T18:00:00.000", { zone: "Europe/Amsterdam" }).toJSDate()
				});
				this.countInitialized = true;
			}*/
		},
		methods: {
			fetchTop() {
				this.$fetch(
					"/request/ranking/gettopfive",
					{ pos: 0 },
					(response) => {
						if (response["status"]) {
							this.rankData = response["data"];
						}
					},
					(error) => {
						
					}
				);
			}
		},
		computed: {},
	};
</script>

<template>
	<div class="home">
		<div class="textCount">
			<span class="labelCount">Open Beta Server is now {{ $app.online ? "Online" : "Offline" }}.</span>
			<br>
			<!--<span class="labelFormat">{{ $app.isMobile ? "(DAYS : HOURS)" : "(DAYS : HOURS : MINUTES : SECONDS)" }}</span>-->
		</div>
		<div class="countdown" v-show="countInitialized">
		</div>
		<!--<span class="labelFormat">Saturday 28th of January, 2023 at 06:00 PM (CET)</span>-->
		<div class="stand" v-if="rankData.length > 0">
			<span class="title">Rankings<br><br>Top 3</span>
			<div class="nameTag three" v-if="rankData.length > 2"><span>{{ 'Lv. ' + rankData[2].level }}</span><span>{{ rankData[2].name }}</span></div>
			<div class="nameTag two" v-if="rankData.length > 1"><span>{{ 'Lv. ' + rankData[1].level }}</span><span>{{ rankData[1].name }}</span></div>
			<div class="nameTag one"><span>{{ 'Lv. ' + rankData[0].level }}</span><span>{{ rankData[0].name }}</span></div>
			<img :src="'/request/imagedb/characters/' + rankData[0].name + '.png'" class="numberOne">
			<img :src="'/request/imagedb/characters/' + rankData[1].name + '.png'" class="numberTwo" v-if="rankData.length > 1">
			<img :src="'/request/imagedb/characters/' + rankData[2].name + '.png'" class="numberThree" v-if="rankData.length > 2">
		</div>
	</div>
</template>

<style scoped>

.nameTag.three {
	position: absolute;
	bottom: 100px;
	right: 20px;
	display: flex;
}

.nameTag.two {
	position: absolute;
	bottom: 100px;
	left: 20px;
	display: flex;
}

.nameTag.two {
	position: absolute;
	bottom: 100px;
	left: 20px;
	display: flex;
}

.nameTag.one {
	position: absolute;
	bottom: 135px;
	left: 0;
	right: 0;
	margin: 0 auto;
	display: flex;
}

.nameTag span {
	color: #fff;
	font-size: 12px;
	width: 100%;
	text-align: center;
}

.nameTag {
	width: 80px;
    height: 40px;
	background-color: rgba(0, 0, 0, 0.65);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.numberThree {
	position: absolute;
	bottom: 17px;
	right: 10px;
}

.numberTwo {
	position: absolute;
	bottom: 17px;
	left: 10px;
}

.numberOne {
	position: absolute;
	bottom: 45px;
	left: 0;
	right: 0;
	margin: 0 auto;
}

.stand span.title {
	position: absolute;
	display: block;
	font-family: "5x5 Pixel";
	font-size: 20px;
	top: 100px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: max-content;
	height: max-content;
	color: #fffff5;
	text-align: center;
}

.textCount {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.labelFormat {
	font-size: 16px;
}

.labelCount {
	font-size: 24px;
}

.stand {
	position: relative;
	width: 369px;
	height: 363px;
	background-image: url("~@/assets/rankingstand.png");
	background-repeat: no-repeat;
	bottom: 0;
}

.landing {
	width: 100%;
	min-height: 100%;
	height: max-content;
	display: flex;
	justify-content: center;
}

.countdown {
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.home {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: nowrap;
	gap: 20px;
	overflow: hidden;
}

span {
	text-align: center;
}

</style>