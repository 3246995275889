import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView,
		meta: { title: "Home" }
	},
	{
		path: "/register",
		name: "register",
		component: () => import("../views/Register.vue"),
		meta: { title: "Sign Up" }
	},
	{
		path: "*", redirect: "/"
	}
];

const router = new VueRouter({
	mode: "history",
	routes
});

export default router;
