<script>
	import Vue from "vue";

	export default {
		components: {},
		props: {},
		data() {
			return {
				innerWidth: 0,
				innerHeight: 0,
				isMobile: false,
				isAbsoluteMobile: false,
				isTablet: false,
				isDesktopSmall: false,
				online: true,

				menu: false
			};
		},
		created() {
			Vue.prototype.$app = this;
			Vue.prototype.$fetch = this.fetch;
			this.onResize();
		},
		mounted() {
			window.addEventListener("resize", this.onResize);

			setTimeout(() => {
				this.checkGameAvailability();
			}, 1000);
			setInterval(() => {
				this.checkGameAvailability();
			}, 15000);
		},
		methods: {
			checkGameAvailability() {
				this.$fetch(
					"/request/game/isonline",
					{},
					(response) => {
						this.online = response.status ? 1 : 0;
						if (response.status) {
							this.onlineCount = response.online;
						}
					},
					(error) => {
						
					}
				);
			},
			closeContext() {
				if (this.menu) {
					this.menu = false;
				}
			},
			onResize() {
				this.innerWidth = window.innerWidth;
				this.innerHeight = window.innerHeight;
				this.isDesktopSmall = window.innerWidth <= 1300;
				this.isTablet = window.innerWidth <= 900;
				this.isMobile = window.innerWidth <= 700;
				this.isAbsoluteMobile = window.innerWidth <= 500;
			},
			navigate(arg, requireLogged = false) {
				if (requireLogged && this.loggedin < 1) {
					return;
				}
				if (
					typeof arg === "object" &&
					arg.name == this.$route.name &&
					((arg.params && _.isEqual(this.$route.params, arg.params)) ||
						(!arg.params &&
							(!this.$route.params || _.isEmpty(this.$route.params))))
				) {
					return;
				}
				this.$router.push(isNaN(arg) ? arg : this.$router.options.routes[arg]);
			},
			fetch(url, data, actions, actionf, hasfile = false) {
				var headers = new Headers();
				if (!hasfile) {
					headers.append("Content-Type", "application/x-www-form-urlencoded");
				}

				var dataEncoded = hasfile ? new FormData() : new URLSearchParams();
				for (let key in data) {
					dataEncoded.append(key, data[key]);
				}

				var requestData = {
					method: "POST",
					credentials: "include",
					headers: headers,
					body: dataEncoded,
					redirect: "follow",
					mode: "cors",
				};

				fetch(url, requestData)
					.then((response) => response.json())
					.then((result) => {
						actions(result);
					})
					.catch((error) => {
						actionf(error);
					});
			}
		},
		computed: {},
		metaInfo() {
			return {
				title: "Adventurer Online",
				meta: [
					{
						name: "description",
						content:
							"Adventurer Online is a tastefully and professionally customized edition of the good old classic MapleStory with various improvements and unique systems.",
					},
					{
						property: "og:title",
						content: "Adventurer Online",
					},
					{ property: "og:site_name", content: "Adventurer Online" },
					{ property: "og:type", content: "website" },
					{ name: "robots", content: "index,follow" },
				],
			};
		},
	};
</script>

<template>
	<div class="app" @click="closeContext">
		<video autoplay muted loop class="videobg">
			<source src="~@/assets/bgvid.mp4" type="video/mp4">
		</video>
		<div class="header">
			<div class="logo"></div>
		</div>
		<div class="page">
			<div class="title">
				<span>{{ $route.meta.title }}</span>
			</div>
			<div class="content">
				<router-view />
			</div>
		</div>
		<div id="content" class="context" ref="content" v-if="menu">
			<div class="contextButton" @click="$app.navigate({ name: 'home' });">
				<span>Home</span>
			</div>
			<div class="contextButton" @click="$app.navigate({ name: 'register' });">
				<span>Sign Up</span>
			</div>
			<div class="contextButton disabled" @click="$app.navigate({ name: 'download' });">
				<span>Downloads</span>
			</div>
			<div class="contextButton disabled" @click="$app.navigate({ name: 'rank' });">
				<span>Account</span>
			</div>
		</div>
		<div class="footer">
			<a href="https://discord.com/invite/adventurer" target="_blank"><div class="shop button"></div></a>
			<div class="menu button" @click.stop="menu = !menu;"></div>
			<a href="https://forum.adventureronline.com/" target="_blank"><div class="shortcut button"></div></a>
		</div>
	</div>
</template>

<style scoped>

.videobg {
	position: fixed;
	min-width: 100%;
	min-height: 100%;
}

.contextButton span {
	font-family: "5x5 Pixel";
	font-size: 12px;
	font-weight: normal;
	color: #fff;
	-webkit-text-fill-color: #fff;
	-webkit-text-stroke-width: 0.2px;
	-webkit-text-stroke-color: #2288aa;
}

.contextButton {
	width: 120px;
	height: 40px;
	background-size: 100% 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url("~@/assets/contextButton.png");
	display: flex;
	align-items: center;
	justify-content: center;
}

.contextButton:hover {
	filter: brightness(1.2);
}

.context {
	position: fixed;
	width: 135px;
	height: 225px;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	background-image: url("~@/assets/contextMenu.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	padding-top: 75px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.content {
	position: absolute;
	width: 95%;
	/*height: 80%;
	bottom: 5%;*/
	height: 84%;
    bottom: 1%;
	right: 0;
	left: 0;
	margin: 0 auto;
	overflow-y: auto;
	overflow-x: hidden;
}

.title span {
	font-family: "5x5 Pixel";
	font-size: 16px;
}

.title {
	position: absolute;
	width: 90%;
	height: 3%;
	top: 2%;
	left: 0;
	right: 0;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.notice {
	background-image: url("~@/assets/notice.png");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.button:hover {
	cursor: url("~@/assets/cursorPointer.png"), auto;
}

.button {
	width: 90px;
	height: 50px;
	background-size: contain;
	background-position: center 95%;
	background-repeat: no-repeat;
}

.shortcut:hover {
	background-image: url("~@/assets/forumHover.png");
}

.shortcut {
	background-image: url("~@/assets/forum.png");
}

.shop:hover {
	background-image: url("~@/assets/discordHover.png");
	/*cursor: url("~@/assets/cursorShop.png"), auto;*/
}

.shop {
	background-image: url("~@/assets/discord.png");
}

.menu:hover {
	background-image: url("~@/assets/menu_hover.png");
}

.menu {
	position: relative;
	background-image: url("~@/assets/menu.png");
}

.logoMobile {
	display: none;
}

.logo:hover {
	filter: brightness(1.5) saturate(1.2);
}

.logo {
	width: 600px;
	height: 250px;
	max-width: 100%;
	max-height: 100%;
	background-image: url("~@/assets/logo.png");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	transition: 0.5s all;
}

.footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 50px;
	background-image: url("~@/assets/statusbar.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	gap: 10px;
}

.page {
	position: absolute;
	top: 25%;
	width: 50%;
	max-width: 700px;
	height: calc(75% - 100px);
	/*min-height: 320px;*/
	left: 0;
	right: 0;
	margin: 0 auto;
	background-image: url("~@/assets/window.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.header {
	position: fixed;
	width: 100%;
	height: 25%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.landing {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	position: relative;
}

@media only screen and (max-width: 700px) {
	.page {
		width: 90%;
		height: calc(75% - 50px);
	}

	.title span {
		font-size: 14px;
	}
}

@media only screen and (max-width: 300px) {
	.page {
		width: 99%;
	}

	.title span {
		font-size: 12px;
	}
}
</style>

<style>
body {
	margin: 0;
	cursor: url("~@/assets/cursor.png"), auto;
}

input {
	cursor: url("~@/assets/cursorText.png") 0 15, auto;
}

.app {
	position: static;
	width: 100vw;
	height: 100vh;
	margin: 0;
	background-image: url("~@/assets/bglith.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center bottom;
	font-family: "arial";
}

.disabled {
    filter: brightness(0.75) saturate(0);
    pointer-events: none;
}

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
	background-color: #9aacbd;
	outline: 1px solid #9aacbd;
	height: 5px;
}

@font-face {
	font-family: "5x5 Pixel";
	src: url("~@/assets/fonts/5x5-Pixel.woff2") format("woff2"),
		url("~@/assets/fonts/5x5-Pixel.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

span,
img {
	user-select: none;
	-webkit-user-drag: none;
}

@media only screen and (max-width: 700px) {
	#cnt_4,
	#cnt_5,
	#cnt_6,
	#cnt_7,
	.cntSeparator {
		display: none;
	}
}
</style>